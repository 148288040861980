import React from "react";
import {
  Grid,
  Typography,
  Stack,
  IconButton,
  Box,
  Link,
  useMediaQuery,
} from "@mui/material";
import { EmailOutlined, Instagram } from "@mui/icons-material";

const Contact = () => {
  const isMobile = useMediaQuery("(max-width: 1024px)");

  return (
    <div style={{ maxWidth: "1000px", padding: isMobile ? "8%" : "100px" }}>
      {/* Sezione principale: Foto a sinistra e Contatti a destra */}
      <Grid container>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-end",
          }}
        >
          <Stack
            spacing={2}
            sx={{
              fontFamily: "degular",
              fontSize: "1.2rem",
              letterSpacing: "1.5px",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              Contact
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton>
                <EmailOutlined fontSize="small" />
              </IconButton>
              <Link
                sx={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "1rem",
                }}
                href="mailto:sergioandretti1@gmail.com"
              >
                sergioandretti1@gmail.com
              </Link>
            </Stack>

            <Stack direction="row" alignItems="center" spacing={1}>
              <IconButton>
                <Instagram fontSize="small" />
              </IconButton>
              <Link
                sx={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "1rem",
                }}
                href="https://www.instagram.com/sergio.andretti"
                target="_blank"
              >
                @sergio.andretti
              </Link>
            </Stack>
          </Stack>
        </Grid>
      </Grid>

      <Stack
        sx={{
          display: "flex",
          textAlign: "left",
          margin: "0 auto",
          justifyContent: "center",
          alignItems: "start",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {/* Sezione Biografia */}
        <Box style={{ margin: "35px 0 15px 0" }}>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            Biography
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "degular",
              fontSize: isMobile ? "14px" : "16px",
              marginTop: "10px",
              letterSpacing: "1.5px",
              textAlign: "left",
              lineHeight: "1.8",
              paddingTop: "10px",
            }}
          >
            Sergio Andretti transforms ordinary moments into extraordinary
            narratives through his lens.{" "}
            <b style={{ fontWeight: 500 }}>
              Rooted in Berlin's tumultuous creative landscape
            </b>
            , he has cultivated a distinctive photographic style that transcends
            traditional boundaries. His photographic journey spans multiple
            genres, revealing an extraordinary range of creative expression,
            from <b style={{ fontWeight: 500 }}>Event</b> to{" "}
            <b style={{ fontWeight: 500 }}>Portrait</b> and
            <b style={{ fontWeight: 500 }}> Fashion Photography</b>. His
            professional trajectory is marked by compelling collaborations with
            prestigious platforms like L’Officiel Italia, SSheena, Siegessäule
            magazine and Giffoni Film Festival. What distinguishes Andretti is
            his <b style={{ fontWeight: 500 }}>innovative visual approach</b> .
            He masterfully manipulates light, texture, and post-processing
            techniques to create surreal, almost{" "}
            <b style={{ fontWeight: 500 }}>hypnotic imagery</b>. His work often
            features striking contrasts, transforming subjects into living
            sculptures of shadow and luminescence. With a professional
            photography diploma from the Istituto Italiano di Fotografia and a
            background in cultural mediation, Sergio Andretti continues to push
            the boundaries of photographic art, turning each frame into a
            profound visual poem.
          </Typography>
        </Box>

        {/* Sezione Interviste */}
        <Box style={{ marginTop: "10px" }}>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            Clients
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "degular",
              fontSize: isMobile ? "14px" : "1.2rem",
              marginTop: "10px",
              letterSpacing: "1.5px",
              textAlign: "left",
              lineHeight: "1.8",
            }}
          >
            Special Media SDL, L’Officiel Italia, SSHEENA, Artribune Magazine,
            The Greatest Magazine, Giffoni Film Festival, Cosmoprof Worldwide,
            Consorzio CIAL, Esprinet Spa.
          </Typography>
        </Box>
      </Stack>
    </div>
  );
};

export default Contact;
